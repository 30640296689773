import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import images from '../images/banner.jpg';

import history from '../components/history'
import ReactGA from 'react-ga';
class Home extends Component {
    constructor(props) {
        super(props);
        this.state={
            aboutContenet:[],
            articalDetails:[],
            blogDetails:[],
            conditionAbout: false,
            mediaDetail:[]
        }

     
    }
    openPop = e => {
    this.setState({ conditionAbout: !this.state.conditionAbout });
    // console.log("change", this.handleClick);
    };
    checkId(slug, id ){
        // window.location.href = "/BlogDetail/${slug}/${id}";
         history.push(`/BlogDetail/${slug}/${id}`)
        console.log('dsds', slug, id);
    }

    mediaData(){	
        fetch(`https://sameeraggarwal.com/api/getlatestMedia`)
        .then((response) => {			
                return response.json();
            })
            .then((data) => {
                console.log('asd', data)
                this.setState({ 
                    mediaDetail: data.result, 
                })
                console.log('ckeck', this.state.mediaDetail)
            
            })
        }
    
        

    articalData(){	
        fetch(`https://sameeraggarwal.com/api/getlatestArticles`)
        .then((response) => {			
                return response.json();
            })
            .then((data) => {
                console.log('asd', data)
                this.setState({ 
                    articalDetails: data.result, 
                })
                console.log('amitdd', this.state.articalDetails)
            
            })
        }
        
        aboutdata(){	
        fetch(`https://sameeraggarwal.com/api/about`)
        .then((response) => {			
                return response.json();
            })
            .then((data) => {
                console.log('asd', data)
                this.setState({ 
                    aboutContenet: data.result, 
                })
                console.log('ckeck', this.state.aboutContenet)
            
            })
        }

        blogsData(){	
            fetch(`https://sameeraggarwal.com/api/getlatestBlogs`)
            .then((response) => {			
                    return response.json();
                })
                .then((data) => {
                    console.log('blogdata', data)
                    this.setState({ 
                        blogDetails :data.result, 
                    })
                })
            }   
            
            
            separateElement () { 
                
            }

            initializeReactGA() {
                ReactGA.initialize('UA-164684587-1');
                ReactGA.pageview('/Home');
            }
            
        
        componentDidMount() {
            this.articalData();
            this. blogsData();
            this.aboutdata();
            this.mediaData();
            this.initializeReactGA(); 
        }

    render() {
        

         

        return (
            <div>
                <div className="bannerImages" style={{ backgroundImage: `url(${images})` }}>
                    <Header />
                    <div className="container">
                        <div className="bannerContent">
                            <h1>Sameer Aggarwal</h1>
                            <p>Father | Entrepreneur | Writer | Speaker</p>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="container">
                        <div className="aboutCont">
                            <h3>About Sameer</h3>
                            <p  className=
                                        {
                                        this.state.conditionAbout
                                        ? "ShadBoxv disabledView"
                                        : "ShadBoxv"
                                        }
                            >
                                <span>
                                    Sameer is the founder of Revfin (Revfin Services Private Limited), a digital lending platform that can underwrite financially excluded and underserved segments. He is also a member of the Board of Directors of Aristo Securities Private Limited, a Non-banking Finance Company (NBFC) regulated by the Reserve Bank of India (RBI).
                                </span>
                                <span>
                                Sameer also provides expert views and writes articles in popular print and online media on various topics including leadership, start-ups, entrepreneurship, fintech, lending and risk management. He also writes a blog on random topics, sharing his personal experiences and insights. Sameer also enjoys mentoring students and young professionals in their corporate or entrepreneurial journey.
                                </span>

                                <span>
                                Prior to setting up his entrepreneurial venture, Sameer has held several leadership positions in banking and consumer lending. He has deep expertise in credit risk management, analytics and credit product/profitability management. While Sameer spent most of his professional career in the United Kingdom, he has experience of working across six continents with a variety of customer segments and consumer lending products.
                                </span>

                                <span>
                                Sameer did his senior years of schooling in Delhi from Air Force Bal Bharti School (AFBBS). He has formal degrees in Management and Engineering and is an alumnus of Indian Institute of Technology, Kharagpur (IIT Kharagpur). Sameer has received several awards including an NBFC industry Emerging Leader Award in 2019.
                                </span>
                                <span>
                                    Sameer lives in New Delhi and is married with a 5 year old son. His father is a retired Air Force officer and his mother is a retired Lecturer. He has an elder sister who lives in Gurgaon with her family.
                                </span>

				            </p>
                            <div className="buttonAra">
                                <div className="RedBtn" onClick={this.openPop}>
                                    <span>Read More</span>
                                </div>
                                <div className="RevfinBtn">
                                    <a href="https://www.revfin.in/" target="_blank" >Go to Revfin</a>
                                </div>
                                <div className="clearfix"></div>
                                <div className="fabCon">
                                    <ul>
                                        <li><a href="https://www.linkedin.com/in/sameer-aggarwal-39069a/" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        <li><a href="https://twitter.com/SameerAggarwal8" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a href="https://www.instagram.com/sameer.aggarwal.792/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                        <li><a href=" https://www.facebook.com/sameer.aggarwal.792" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="container">
                        <div className="articals">
                            <div className="row">
                                <div className="col-md-4 col-xs-12 col-sm-12">
                                    <h3 className="articalHeading">Blogs</h3>
                                    <div className="PannelArt rud" >
                                        {this.state.blogDetails.slice(0, 2).map(
                                            (item, i) => (
                                                    <div className="blogArt withBgColor" key={i}>
                                                        <figure className="sepm ">
                                                            <img src={item.blogimageUrl} />
                                                        </figure>
                                                        <p>{item.title}</p>
                                                        <a href=""  onClick={e =>  this.checkId(item.slug, item.id)}                    
                                                        >Read More</a>
                                                    </div>        
                                            ))}
                                        </div>
                                            
                                </div>
                                <div className="col-md-4 col-xs-12 col-sm-12">
                                    <h3 className="articalHeading">Articles</h3>
                                    <div className="PannelArt">
                                        {this.state.articalDetails.slice(1, 3).map(
                                        (object, i) => (
                                            <div className="blogArt withBgColor" key={i}>
                                                <figure className="sepm" >
                                                <img src={object.articleimageurl} />
                                                </figure>
                                                <p>{object.title}</p>
                                                <a href={object.linkedUrl}  target="_blank">Read More</a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="col-md-4 col-xs-12 col-sm-12">
                                    <h3 className="articalHeading">Media</h3>
                                    <div className="PannelArt maxh">
                                    {this.state.mediaDetail.slice(1, 3).map(
                                        (object, i) => (
                                            <div className="blogArt withBgColor" key={i}>
                                                 <figure className="sepm seas" >
                                                    <img src={object.mediaimage} />
                                                </figure>
                                                <p>{object.title}</p>
                                                <a href={object.linkedUrl} target="_blank">Read More</a>
                                        </div>   
                                        ))}
                                                                             
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}

export default Home;
