import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import ReactGA from 'react-ga';



import * as serviceWorker from "./serviceWorker";

ReactDOM.hydrate(<App />, document.getElementById("root"));

serviceWorker.unregister();



