import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    Redirect,
    Switch,
    withRouter
} from "react-router-dom";


class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            condition: false
        };
    }

    handleClick = e => {
        this.setState({ condition: !this.state.condition });
        console.log("sdfas", this.handleClick);
    };



    render() {
        return (
            <div>
                <div
                    className={this.state.condition ? "shade" : ""}
                    onClick={this.handleClick}
                ></div>
                <header >
                    <nav className={this.state.condition ? "animated" : ""}>
                        <ul>
                            <li className="nav-item">
                                <Link
                                    to="/"
                                    className="nav-link"
                                >
                                    Home
                                    </Link>
                            </li>
                            <li>
                                <Link
                                    to="/Blogs"
                                    className="nav-link"
                                >
                                    Blogs
                                    </Link>
                            </li>
                            <li>
                                <Link
                                    to="/Articles"
                                    className="nav-link"
                                >
                                    Articles
                                    </Link>
                            </li>
                            
                            <li>
                                <Link
                                    to="/Media"
                                    className="nav-link"
                                >
                                    Media
                                    </Link>
                            </li>
                            <li>
                            <a href="#moterfooter"
                                    className="nav-link">Contact
                            </a>
                            </li>
                        </ul>
                    </nav>
                </header>
                <div className="barna" onClick={this.handleClick}>
                    <div className="topBar"></div>
                    <div className="midBar"></div>
                    <div className="botBar"></div>
                </div>
            </div>
        );
    }
}

export default Header;
