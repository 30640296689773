import React, { Component } from "react";
import Header from "./Header";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    Redirect,
    Switch,
    withRouter
} from "react-router-dom";
import axios from "axios";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state={
            name:"",
            email:"",
            message:"",
            okContact:false,
            messageContact:""
        }
        this.onChangeContactForm = this.onChangeContactForm.bind(this);
    }


    onChangeContactForm(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    ClickContect (){
        window.location.reload();
    }


    formHandleContact =(e) => {
        e.preventDefault();
        const newOrder = {
            
            name: this.state.name,
            email: this.state.email,
            message: this.state.message,
        }
        console.log('textstatetest', newOrder);

        this.setState({ errors: {} });
        axios.post(`https://sameeraggarwal.com/api/contactUs`, newOrder)
      .then((res) => {
        console.log("done", res);
        this.setState({okContact:res.status, messageContact:res.data.message})
        //window.location.reload();
      })
      .catch(error => {
         console.log("commentfalse");
      });
    }



    render() {

        return (
            <div>
                    <div 
                    className=
                    {
                    this.state.okContact
                    ? "whitbg disabledView"
                    : "whitbg"
                    }
                    >

                    </div>

                    <div className=
                    {
                    this.state.okContact
                    ? "thaxwala disabledView"
                    : "thaxwala"
                    }
                    >
                        <h3>{this.state.messageContact}</h3>
                    {/* <h3>hank you, Your message has been sent.</h3> */}
                    <button onClick={this.ClickContect}>Okay</button>
                    </div>
                <footer id="moterfooter">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-xs-12 col-sm-12">
                                <h3 className="HeadingTestFooter">Get In Touch</h3>
                                <div className="formAra">
                                    <form onSubmit={this.formHandleContact} autoComplete="off" >
                                        <div className="row">
                                            <div className="col-md-6 col-xs-12 col-sm-12">
                                                <div className="formHalfGrid">
                                                    <input type="text" placeholder="Name"
                                                        name="name"
                                                        value={this.state.name}
                                                        onChange={this.onChangeContactForm} 
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-xs-12 col-sm-12">
                                                <div className="formHalfGrid">
                                                    <input type="text" placeholder="Email ID" 
                                                        name="email"
                                                        value={this.state.email}
                                                        onChange={this.onChangeContactForm} 
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="formFullGrid">
                                                    <textarea placeholder="Message"
                                                        name="message"
                                                        value={this.state.message}
                                                        onChange={this.onChangeContactForm} 
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="formFullfGrid">
                                            <button>Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6 col-xs-12 col-sm-12">
                                <div className="foleFooter">
                                    <h3 className="HeadingTestFooter">Quick Links</h3>
                                    <div className="listNav">
                                        <ul>
                                            <li >
                                                <Link
                                                    to="/"
                                                >
                                                    Home
                                            </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/Blogs"
                                                >
                                                    Blogs
                                            </Link>
                                            </li>   
                                            <li>
                                                <Link
                                                    to="/Articles"
                                                >
                                                    Articles
                                            </Link>
                                            </li>
                                            
                                            <li>
                                                <Link
                                                    to="/Media"
                                                >
                                                    Media
                                             </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/Disclaimer"
                                                >
                                                    Disclaimer
                                             </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Footer;
