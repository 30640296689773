import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Redirect } from "react-router-dom";

class Contact extends Component {
    constructor(props) {
        super(props);

    }

    render() {

        return (
            <div>
                <div className="innrBanner">
                    <Header />
                </div>
                <div className="innterpage">

                </div>
                <Footer />
            </div>
        );
    }
}

export default Contact;
