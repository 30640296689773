import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
    BrowserRouter as Router,
    Route,
    Link,
    NavLink,
    Redirect,
    Switch,
    withRouter
} from "react-router-dom";
import Moment from 'react-moment';
import moment from 'moment';

import history from '../components/history'
import ReactGA from 'react-ga';

class Blogs extends Component {
    constructor(props) {
        super(props);
        this.state={
            blogDetails:[]
        }
    }


    blogsData(){	
        fetch(`https://sameeraggarwal.com/api/getlatestBlogs`)
        .then((response) => {			
                return response.json();
            })
            .then((data) => {
                console.log('asd', data)
                this.setState({ 
                    blogDetails :data.result, 
                })
            })
        }


        checkId(slug, id ){
            // window.location.href = "/BlogDetail/${slug}/${id}";
             history.push(`/BlogDetail/${slug}/${id}`)
            console.log('dsds', slug, id);
        }


        initializeReactGA() {
            ReactGA.initialize('UA-164684587-1');
            ReactGA.pageview('/Blogs');
        }
        
            componentDidMount() {
                this.blogsData();
                this.initializeReactGA();
            }



    render() {
        return (
            <div>
                <div className="innrBanner">
                    <Header />
                    <h1 className="mainHeading">Blogs by Sameer</h1>
                </div>
                <div className="innterpage">
                    <div className="container">
                        
                        <div className="mainArt">
                            <div className="row">
                                {this.state.blogDetails.map(
                                        (object, i) => (
                                            <div className="col-md-4 col-xs-12 col-sm-6" key={i}>
                                                <div className="articalsBosx ">
                                                    <div className="fitrbox">
                                                        <img src={object.blogimageUrl} />
                                                    </div>
                                                        <div className="shaperdd withnob">
                                                            <h3>{object.title}</h3>
                                                            <p className="staticcontblog">My personal journey through crises, the opportunities that inevitably came in their wake and
the lessons I learnt</p>
                                                            <div className="diaAra">
                                                            <p><Moment format="DD/MM/YYYY">{object.posteddate}</Moment></p>
                                                                <a href=""  onClick={e =>  this.checkId(object.slug, object.id)}                    
                                                                >Read More</a>         
                                                            <div className="clearfix"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                    )
                                  )
                                }
                             </div>
                            {/* <div className="mediaPara">
                                <h3 className="mainHeading">News</h3>
                                <div className="row">
                                    <div className="col-md-4 col-xs-12 col-sm-4">
                                        <img src={require("../images/scooty.png")} />
                                    </div>
                                    <div className="col-md-4 col-xs-12 col-sm-4">
                                        <img src={require("../images/scooty.png")} />
                                    </div>
                                    <div className="col-md-4 col-xs-12 col-sm-4">
                                        <img src={require("../images/scooty.png")} />
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="liks">
                                    50 Likes <span><i className="fa fa-thumbs-up" aria-hidden="true"></i></span>
                                </div>
                            </div> */}
                            {/* <div className="socialPan">
                                <h3>Share this article on social media</h3>
                                <div className="fabCon">
                                    <ul>
                                        <li><a href=""><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        <li><a href=""><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a href=""><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                        <li><a href=""><i className="fa fa-envelope" aria-hidden="true"></i></a></li>
                                        <li><a href=""><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>

                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Blogs;
