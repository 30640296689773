import React, { Component} from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Switch,
  withRouter
} from "react-router-dom";


import Home from "./components/Home";
import Articles from "./components/Articles";
import Media from "./components/Media";
import Contact from "./components/Contact";
import Blogs from "./components/Blogs";
import Disclaimer from "./components/Disclaimer";
import BlogDetail from "./components/BlogDetail";

import history from './components/history';


import ReactGA from 'react-ga';





class App extends Component {
  


  render() {
    return (
      <div>
        <Router history={history}>
          <div>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/Articles" component={Articles} />
              <Route path="/Blogs" component={Blogs} />
              <Route path="/Media" component={Media} />
              <Route path="/Contact" component={Contact} />
              <Route path="/Disclaimer" component={Disclaimer} />
              <Route path="/BlogDetail/:slug/:id" component={BlogDetail} />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
