import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';
import moment from 'moment';
import history from '../components/history';
import {button, Modal} from 'react-bootstrap';
import ReactGA from 'react-ga';
import axios from "axios";
import {Helmet} from "react-helmet";



class BlogDetail extends Component {
    constructor(props) {
        super(props);
        this.state={
            condition: false,
            conditioncomment:false,
            robs:false,
            techgo:false,
            name: "",
            email: "",
            namef: "",
            emailf: "",
            phonef:"",
            blogId:"",
            commenttext:"",
            allUrl:this.props.match.params.slug,
            allUrlid:this.props.match.params.id,
            blogDetailData:[],
            defaultChecked:true,
            edId:"",
            buttonC:false,
            message:"",
            ok:false,
            CuOk:false,
            blogDetails:[]
        }
        this.handalCheckbox = this.handalCheckbox.bind(this);    
        this.onChange = this.onChange.bind(this);
        this.onChangeForm = this.onChangeForm.bind(this);
    }

    ClickBlog (){
        window.location.reload();
    }
    onChange(e) {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onChangeForm(e){
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    formHandleSubscrib =(e) => {
        e.preventDefault();
        const newOrder = {
            name: this.state.name,
            email: this.state.email,
        }
        this.setState({ errors: {} });
        axios.post(`https://sameeraggarwal.com/api/subscribeBlog`, newOrder)
      .then((res) => {
        console.log("agayasahi", res);
        this.setState({ok:res.status, message:res.data.message})
      })
      .catch(error => {
        console.log("dsfdsfds2");
      });
    }



    formHandleComment =(e) => {
        e.preventDefault();
        const newOrder = {
            blogId:this.props.match.params.id,
            name: this.state.namef,
            email: this.state.emailf,
            phone: this.state.phonef,
            commenttext: this.state.commenttext,
        }
        console.log('textstatetest', newOrder);

        this.setState({ errors: {} });
        axios.post(`https://sameeraggarwal.com/api/addComment`, newOrder)
      .then((res) => {
        console.log("commentdone");
        window.location.reload();
        // this.setState({CuOk:res.status})
      })
      .catch(error => {
         console.log("commentfalse");
      });
    }


    

    formHandlerEdit (e, id){
        e.preventDefault();
        console.log('mycheckid', id)
        const requestOptions = {
            method: "GET",
            //headers: { ...authHeader(), "Content-Type": "application/json" }
            //body: JSON.stringify(query)
          };
          return fetch(
            `https://sameeraggarwal.com/api/likeBlog/${id}`,
            requestOptions
          )
          .then((response) => response.json())
            .then((res) => {
                console.log('first', res);
                if (!res.success) {
                    //this.setState({ tooltip: res.message });
                    window.location.reload();
                    //alert(res.message)
                    console.log('laga')
                } else {
                    console.log("test2", res)
                    window.location.reload();
                }
            }, err => {
              
            })
    }


    handalCheckbox(){
        this.setState({ buttonC: !this.state.buttonC });
   
    }

    checkId(slug, id ){
        // window.location.href = "/BlogDetail/${slug}/${id}";
         history.push(`/BlogDetail/${slug}/${id}`)
        console.log('dsds', slug, id);
    }


    clickHandelClose = e => {
        this.setState({ condition: !this.state.condition });
   
      };

  
      openPop = e => {
        this.setState({ condition: !this.state.condition });
   
      };
      
      gocont = e => {
        this.setState({ techgo: !this.state.techgo });
      }





      clickHandelCloseComment = e => {
        this.setState({  conditioncomment: !this.state.conditioncomment});
   
      };
      
      openPopComment = e => {
          console.log('chal')
            this.setState({ conditioncomment: !this.state.conditioncomment });
      };


        blogsData(){	
            fetch(`https://sameeraggarwal.com/api/getlatestBlogs`)
            .then((response) => {			
                    return response.json();
                })
                .then((data) => {
                    console.log('asd', data)
                    this.setState({ 
                        blogDetails :data.result, 
                    })
                })
            }



    blogsDetailsData(){	
        fetch(`https://sameeraggarwal.com/api/getBlogbyId/${this.state.allUrl}/${this.state.allUrlid}`)
        .then((response) => {			
                return response.json();
            })
            .then((data) => {
                console.log('asd', data)
                this.setState({ 
                    blogDetailData :data.result, 
                })
                console.log('blogsdetailsdetails', this.state.blogDetailData)
                
            })
            
        }


        initializeReactGA() {
            ReactGA.initialize('UA-164684587-1');
            ReactGA.pageview('/BlogDetail');
        }

        componentDidMount(){
            this.blogsDetailsData();
            this.blogsData();
            this.initializeReactGA();
        }



        


    render() {

        
        return (
            <div>
        <div
          className=
          {
            this.state.condition
              ? "ShadBox disabledView"
              : "ShadBox"
          }
          onClick={this.clickHandelClose}
        >
        </div>

          <div 
                className=
                {
                    this.state.ok
                    ? "whitbg disabledView"
                    : "whitbg"
                }
          >

          </div>

        <div className=
            {
                this.state.ok
                ? "thaxwala disabledView"
                : "thaxwala"
            }
            >
            <h3>{this.state.message} </h3>
            <button onClick={this.ClickBlog}>Okay</button>
        </div>


     


        <div className=
          {
            this.state.condition
              ? "pop disabledView"
              : "pop"
          }
        >
          <div
            className="colseBtn" onClick={this.clickHandelClose}>
            x
          </div>
          <div className="popBody" >
            <div className="formarea">
                <form onSubmit={this.formHandleSubscrib} autoComplete="off" >
                    
                    <div className="formgrid">
                        <label>Name</label>
                        <input className="form-control inputwal" 
                               type="text" 
                               name="name"
                               value={this.state.name}
                               onChange={this.onChange} 
                         />
                    </div>
                    <div className="formgrid">
                        <label>Email</label>
                        <input  className="form-control inputwal" 
                        type="text"
                        name="email"
                        value={this.state.email}
                        onChange={this.onChange} 
                       />
                    </div>
                    <div className="formgridchck">
                        <label>
                            <input  type="checkbox" defaultChecked={true} onClick={this.handalCheckbox}/> 
                            <span className="formcontent">I consent to subscribe to your blogs and to receive update(s)/ email(s) from info@sameeraggarwal.com in respect of the said blogs or any new/ updated blogs written by Sameer Aggarwal (“Sameer”). By making use of this website and furnishing my personal/ contact details, I hereby agree that Sameer may contact me either electronically or through phone, to understand my interest in the said blogs. I also agree that Sameer reserves his right to make my details available to his affiliates and partners and I may be contacted by such affiliates and partners for research, views, marketing and other purposes either through email, telephone, text message or by all of the said modes.</span>
                        </label>
                    </div>
                    <button className="sumbitBtn" checked={true}>Subscribe</button>
                    <span className={
                        this.state.buttonC
                        ? "btnShad disabledView"
                        : "btnShad"
                    } >

                    </span>
                </form>
            </div>
          </div>
        </div>


               
                <div className="innrBanner">
                    <Header />
                </div>
       
                <div className="innterpage">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 col-xs-12 col-sm-8">
                                <div className="blogsPannel">
                                    <h1>{this.state.blogDetailData.title}</h1>
                                    <p>By Sameer Aggarwal, Posted on <Moment format="DD/MM/YYYY">{this.state.blogDetailData.posteddate}</Moment></p>
                                    <p className="blogletter">
                                        My personal journey through crises, the opportunities that inevitably came in their wake and the lessons I learnt
                                    </p>
                                    <div className="firgsBlog">
                                        <img src={this.state.blogDetailData.blogimageUrl} />
                                    </div>
                                    {/* <p>
                                       {this.state.blogDetailData.description}
                                    </p> */}
                                    <div className="blogstatic">
                                            
                                            <p>
                                                After many months of pondering (read, procrastinating), I’m writing my first blog. I wanted my first time to be special; to write about something close to my heart. But given the ‘corona’ times we are living in, the only thought on the mind is - Crisis! And what after?!
                                            </p>
                                            <p>
                                                But before I get into it, a bit about me…I started my career in 2006 with a leading international bank in Marketing Analytics (I promise, it was a sexy thing to do back then!). I got bored very soon and made a transition to Risk Analytics (poor choice back then). As an analyst, I found my new work very rewarding &amp; exciting, but to the world it was a boring job done by ‘backend’ geeks.
                                            </p>
                                            <p>
                                                A couple of years later, life took a sharp turn with the global financial crisis as a result of the collapse of Lehmann Brothers. The crisis really started in 2007 with the collapse of the subprime mortgage market in the USA, but it became global in 2008. Its effects were felt for a few years. This was the first real crisis that I witnessed in my life. Since then many crises have come and gone, each leaving behind several opportunities and lessons. 
                                            </p>
                                            <p>
                                                And now in 2020, the world is facing perhaps the biggest crisis in living memory – COVID 19. The corona crisis has become global in a matter of months. From the first traces in China in December 2019, with over 2.5 million cases in 5 months across 185 countries. This crisis is impacting health, economy and society the world over in a bigger way than ever seen before.   
                                            </p>
                                            <p>
                                                Back to my story. It was during the global financial crisis of 2008 that there was a new found interest in the banking world in Risk Management. From being the backend boys, we were now the cynosure of everyone’s eyes! I got my first big career opportunity - I was offered a position in the UK by my bank in 2009. Suddenly I found myself talking to executive management in big boardrooms in our 42-storey headquarter in London. And they were interested in what I had to say. Over the next few years, Risk Management became an integral part of every decision in the banking industry. Stars were shining bright. Even to this date, Risk professionals are in high demand in the banking industry. I know the financial crisis was very bad for many companies and individuals. But like me, several people also got many new opportunities…it is these opportunities that helped the world move on.    
                                            </p>
                                            <p>
                                                My second opportunity came in 2010 with the next crisis. The effects of the financial crisis were still looming large and my company announced large scale restructuring (lay-offs and change of organization structure). The mood was very tense and the first person to be laid off was the head of my department. It again felt like the world around was crumbling - a new mini Crisis was in the making. A few days later I was informed that as part of the restructuring, I would be leading teams in UK and India. This was a big promotion for me in a very short span of time. I was being given a role that was performed by nine people prior to the restructuring. Crisis came and went  but left behind another opportunity for me. Many people lost their jobs, but most of them found something better to do soon after. In the end it all was well, and stars were shining bright again.     
                                            </p>
                                            <p>
                                                I worked as a banker until 2017, when because of a very small and short-lived personal crisis, we made a decision as a family to move back to India and I became an entrepreneur. In my life I have seen many crises, some big, some small. When they were gone, there was always an opportunity knocking…an opportunity that took my life forward…and quickly. But one can’t live life in hope only. One must also prepare for the next crisis, so it becomes easier to get through it.    
                                            </p>
                                            <p>
                                                Two lessons that I learnt to help prepare for crisis and to deal with them effectively are - Perseverance and Risk Management. When the going gets tough, the tough get going…. because there is no other way. We have to remain positive and motivated through any crisis; we try our best and hang in there, we persevere. Some basic principles of risk management that I used to so keenly apply to my work also have relevance in my personal life.    
                                            </p>
                                            <p>
                                                The first of these is Diversification - invest in different types of assets; real estate, stocks, mutual funds, fixed income funds etc. and balance the proportions based on needs, not just based on risk appetite. Also live in different cities and countries, make new friends, meet new people, indulge in new experiences - all these will help you learn how to be creative in solving problems and averting crisis.    
                                            </p>
                                            <p>
                                                The second is Provisioning - keep a provision of funds (rainy day money) in safe and liquid government bonds, fixed deposits, cash in bank, cash at home etc. Keep sufficient amounts to cover all your expenses for at least three months, but ideally for a year. This is in addition to your investments, which are more for long term needs as opposed to emergencies. Make sure you are sufficiently insured; medical, life, loss of job etc. Also make sure your home is stocked with sufficient food for a few days, car is filled with petrol (don’t wait to reach ‘E’), phone is charged, etc. These are habits one needs to form.    
                                            </p>
                                            <p>
                                                The third one is Deleveraging - get rid of your debts. Take loans selectively, like buying a house, education and for other asset building or productive purposes. Avoid loans to fulfil wants like phones and other electronics.  Only take loans that you can comfortably repay…and try not to  use credit cards...if you do, repay the entire outstanding amount every month.    
                                            </p>
                                            <p>
                                                I have seen many crises in my life, mostly small but also some very big ones. I have learnt to deal with them. My past experiences give me hope, that one day the crisis will be gone and convert into an opportunity and will leave behind a world of wisdom to tackle the next one.
                                            </p>
                                            <p>
                                                Personally, for me as a new entrepreneur, the current corona crisis is the biggest crisis yet. Just when I had managed to get my start-up on its feet, we are now in a situation where no cash is coming in, but outflows continue at normal pace. But deep in my heart, I do believe that opportunities shall follow and…we shall overcome this crisis someday (very soon)!    
                                            </p>
                                            <p>
                                                As a young boy I once read in the Reader’s Digest Quotable Quotes section - Never fear shadows, they simply mean there’s a light shining somewhere nearby. The shadow of the crisis will turn in to light…the light is coming…sooooon!
                                            </p>
                                            <p>
                                                Stay Happy, Stay Healthy, Stay at Home!
                                            </p>
                                    </div>
                                    {/* <h3>Lorem Ipsum Site amet</h3>
                                    <ul className="listBlog">
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </li>
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </li>
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </li>
                                        <li>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                        </li>
                                    </ul> */}
    <br></br>
    <div className="clearfix"></div>
                        <div className="socialPan">
                                <div className="iconsLike">
                                    <form onSubmit={(e) => this.formHandlerEdit(e, this.state.blogDetailData.id)} autoComplete="off" >
                                        <button >
                                            <span className="likecount">
                                                {this.state.blogDetailData.totallike}
                                            </span>
                                            Likes
                                            <span className="thumbIcon"><i className="fa fa-thumbs-up" aria-hidden="true"></i></span>  
                                        </button>
                                    </form>
                                </div>
                            <h3>Share this article on social media</h3>
                            <div className="fabCon">
                                <ul>
                                    <li><a href="https://www.linkedin.com/shareArticle?mini=true&url=http://sameeraggarwal.com/BlogDetail/never-fear-shadows/5ea7d4ae6d52f76da2a57070" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                    <li><a href="https://twitter.com/intent/tweet?via=http://sameeraggarwal.com/BlogDetail/never-fear-shadows/5ea7d4ae6d52f76da2a57070&text=You_are_amazing" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                    <li><a href="https://www.facebook.com/sharer/sharer.php?u=https://sameeraggarwal.com/BlogDetail/never-fear-shadows/5ea90b8848f96562a508f5ef" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                </ul>
                            </div>
                        </div>                       
                       <h3 className="newformfont">Post Your Comment<span></span></h3>
                       <div className="">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="Changeform">
                                    {/* <p>Newest First Subscribe Via e-mail</p> */}
                                    <form onSubmit={this.formHandleComment} autoComplete="off" >
                                        <div className=
                                            {
                                                this.state.conditioncomment
                                                  ? "npopshadow disabledView"
                                                  : "npopshadow"
                                              }
                                        ></div>
                                        <div className=
                                             {
                                                this.state.conditioncomment
                                                  ? "npop disabledView"
                                                  : "npop"
                                              }
                                        >
                                                            <div
                                                                    className=
                                                                    {
                                                                        this.state.rob
                                                                        ? "commentclos disabledView"
                                                                        : "commentclos"
                                                                    }
                                                                    onClick={this.clickHandelCloseComment}
                                                                    >
                                                                        X
                                                                    </div>
                                            <div className="clearfix"></div>

                                            <div className="row">
                                                <div className="col-md-12 col-xs-12 col-sm-12 parMargen">
                                                    <div className="formgrid">
                                                        <label>Name</label>
                                                        <input type="text"  autoComplete="off"
                                                            className="form-control inputwal"
                                                            name="namef"
                                                            value={this.state.namef}
                                                            onChange={this.onChangeForm} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-xs-12 col-sm-12 parMargen">
                                                <div className="formgrid">
                                                            <label>Email</label>
                                                            <input type="text"   autoComplete="off"
                                                            className="form-control inputwal"
                                                                name="emailf"
                                                                value={this.state.emailf}
                                                                onChange={this.onChangeForm} 
                                                            />
                                                        </div>
                                                </div>
                                                <div className="col-md-12 col-xs-12 col-sm-12 parMargen">
                                                <div className="formgrid">
                                                        <label>Phone</label>
                                                        <input type="text" autoComplete="off"
                                                        className="form-control inputwal"
                                                            name="phonef"
                                                            value={this.state.phonef}
                                                            onChange={this.onChangeForm} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="formBtn pads20">
                                                <button className="post">Submit</button>
                                            </div>
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-md-12 parMargen">
                                                <div className="FormAra">
                                                    {/* <label>Message</label> */}
                                                    <textarea
                                                        name="commenttext"
                                                        value={this.state.commenttext}
                                                        onChange={this.onChangeForm} 
                                                        onClick={this.gocont}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className="relatbox">
                                        <span className=
                                            {
                                                this.state.techgo
                                                  ? "nott disabledView"
                                                  : "nott"
                                              }
                                        ></span>
                                        <div className="formBtn">
                                            <div className="post" onClick={this.openPopComment}>Post Comment</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="row m50 auto">
                            <div className="col-md-12">
                                    {
                                        this.state.blogDetailData.comment ? (
                                        this.state.blogDetailData.comment.map((obj,i) =>(
                                            <div className="allfull" key={i}>
                                                <div className="gridB">
                                                        <p><b>{obj.userName}</b> {obj.commenttext}</p>
                                                        <span className="tiemdates"><i className="fa fa-calendar" aria-hidden="true"></i> <Moment format="DD/MM/YYYY">{obj.commentTime}</Moment>  |  <i className="fa fa-clock-o" aria-hidden="true"></i> <Moment format="HH:mm">{obj.commentTime}</Moment></span>
                                                </div>  
                                            </div>
                                        ))
                                        ) :null
                                    }
                            </div>
                        </div>
                    

                                </div>
                            </div>
                            <div className="col-md-4 col-xs-12 col-sm-4">
                                <div className="pannelLeftBlock">
                                    <div className="blosLeft">
                                        <h3>Recent Blog</h3>
                                        <ul className="list_blogs">
                                        {this.state.blogDetails.map(
                                        (object, i) => (
                                            <li key={i}><a href=""  onClick={e =>  this.checkId(object.slug, object.id)}                    
                                            >{object.title}</a></li>
                                            )
                                        )
                                        }
                                            
                                        </ul>
                                    </div>
                                    {/* <div className="blosLeft">
                                        <h3>Popular Blog</h3>
                                        <ul className="list_blogs">
                                            <li><a href="">Lorem ipsum dolor sit amet, consectetur</a></li>
                                            <li><a href="">Lorem ipsum dolor sit amet, consectetur</a></li>
                                            <li><a href="">Lorem ipsum dolor sit amet, consectetur</a></li>
                                            <li><a href="">Lorem ipsum dolor sit amet, consectetur</a></li>
                                            <li><a href="">Lorem ipsum dolor sit amet, consectetur</a></li>
                                        </ul>
                                    </div> */}
                                    <div className="blogContact">
                                        <h3>Contact</h3>
                                        <div className="fabCon">
                                            <ul>
                                            <li><a href="https://www.linkedin.com/in/sameer-aggarwal-39069a/" target="_blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        <li><a href="https://twitter.com/SameerAggarwal8" target="_blank"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a href="https://www.instagram.com/sameer.aggarwal.792/" target="_blank"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                        <li><a href=" https://www.facebook.com/sameer.aggarwal.792" target="_blank"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                            </ul>
                                            <button  onClick={this.openPop} >Subscribe</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default BlogDetail;
