import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';
import moment from 'moment'
import ReactGA from 'react-ga';




class Articles extends Component {
    constructor(props) {
        super(props);
            this.state={
                articalDetails:[],
               
            }
    }

    articalData(){	
        fetch(`https://sameeraggarwal.com/api/getlatestArticles`) 
        .then((response) => {			
                return response.json();
            })
            .then((data) => {
                console.log('asd', data)
                this.setState({ 
                    articalDetails: data.result, 
                })
                console.log('ckeck', this.state.articalDetails)
            
            })
        }
        initializeReactGA() {
            ReactGA.initialize('UA-164684587-1');
            ReactGA.pageview('/Articles');
        }


        
        componentDidMount() {
            this.articalData();
            this.initializeReactGA();
        }


        

    render() {

        return (
            <div>
                <div className="innrBanner">
                    <Header />
                    <h1 className="mainHeading">Articles by Sameer</h1>
                </div>
                <div className="innterpage">
                    <div className="container">
                        
                        <div className="mainArt">
                            <div className="row">
                            {this.state.articalDetails.map(
                                    (object, i) => (
                                        <div className="col-md-4 col-xs-12 col-sm-6" key={i}>
                                            <div className="articalsBosx">
                                                <div className="fitrbox">
                                                    <img src={object.articleimageurl} />
                                                </div>
                                                <div className="shaperdd">
                                                    <h3>{object.title}</h3>
                                                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do </p> */}
                                                    <div className="diaAra">
                                                    {/* <p><Moment format="DD/MM/YYYY">{object.posteddate}</Moment></p> */}
                                                    <a href={object.linkedUrl} className="newst" target="_blank">Read More</a>
                                                    <div className="clearfix"></div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                            }


                                
                               
                            </div>
                            {/* <div className="socialPan">
                                <h3>Share this article on social media</h3>
                                <div className="fabCon">
                                    <ul>
                                        <li><a href=""><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                                        <li><a href=""><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                                        <li><a href=""><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                                        <li><a href=""><i className="fa fa-envelope" aria-hidden="true"></i></a></li>
                                        <li><a href=""><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                                    </ul>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Articles;
