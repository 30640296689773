import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Redirect } from "react-router-dom";
import Moment from 'react-moment';
import moment from 'moment'
import ReactGA from 'react-ga';



class Disclaimer extends Component {
    constructor(props) {
        super(props);
            this.state={
                articalDetails:[],
               
            }
    }

    articalData(){	
        fetch(`https://sameeraggarwal.com/api/getlatestMedia`)
        .then((response) => {			
                return response.json();
            })
            .then((data) => {
                console.log('asd', data)
                this.setState({ 
                    articalDetails: data.result, 
                })
                console.log('ckeck', this.state.articalDetails)
            
            })
        }


        initializeReactGA() {
            ReactGA.initialize('UA-164684587-1');
            ReactGA.pageview('/Disclaimer');
        }
        
        componentDidMount() {
            this.articalData();
            this.initializeReactGA();
        }

    render() {

        return (
            <div>
                <div className="innrBanner">
                    <Header />
                    <h1 className="mainHeading">Disclaimer</h1>
                </div>
                <div className="innterpage">
                    <div className="container">
                        
                        <div className="mainArt">
                            <div className="row">
                            <div className="col-md-12"></div>
                                <p>
                                    The views and opinions expressed on this website or expressed in any articles/ blogs available on this website are those of the author and does not constitute any opinion/ advice by the author. The information provided through this website is not intended to create any relationship of any nature whatsoever between the author and the reader and is not meant for advertising the services of the author or for the purposes of any solicitation of work by the author. 
                                </p>
                                <p>
                                    The author does not warrant the accuracy and completeness of the contents of this website or the articles/ blogs available thereon and, the readers are requested to seek formal advice prior to acting upon any information or views expressed by the author. Further, the applicable laws are dynamic and subject to change, clarification and amendment by the relevant authorities, which may impact the contents of this website including the articles and the blogs available thereon. This website and its contents are exclusive copyright of the author and may not be copied, reproduced or otherwise used by the visitor of this website without the author’s written prior permission. 
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Disclaimer;
